import { j as a } from "./chunks/jsx-runtime.82a9ddf6.js";
import { forwardRef as S, useState as _ } from "react";
import { c } from "./chunks/clsx.0d472180.js";
import { f as e } from "./chunks/styles.9b2ce419.js";
import { m as k } from "./chunks/motion.24089995.js";
import { Stack as T } from "./stack.mjs";
import { Text as j } from "./text.mjs";
import "./chunks/utils.60e8c32c.js";
import "./chunks/lodash.14d7cb6d.js";
import "./chunks/cn.2ea4f521.js";
import "./chunks/TypographyElement.fa6e5999.js";
const v = {
  type: "spring",
  bounce: 0.2,
  duration: 0.6
}, d = S(
  ({
    size: l = "m",
    variant: m = "blue",
    labelHidden: p,
    label: u,
    disabled: i,
    labelPlacement: f = "right",
    checked: r,
    onChange: t,
    defaultChecked: h = !1,
    ...g
  }, y) => {
    const [b, x] = _(h), s = typeof r < "u", o = s ? r : b, N = (n) => {
      t == null || t(n), s || x(n.target.checked);
    };
    return /* @__PURE__ */ a.jsxs(
      T,
      {
        className: c(
          e.root,
          e[m],
          e[l],
          e[f],
          o && e.checked,
          i && e.disabled
        ),
        as: "label",
        type: "horizontal",
        children: [
          /* @__PURE__ */ a.jsx(j, { variant: "s", as: "span", className: c(e.label, p && "visuallyHidden"), children: u }),
          /* @__PURE__ */ a.jsx(
            "input",
            {
              ref: y,
              type: "checkbox",
              ...g,
              checked: o,
              disabled: i,
              className: e.input,
              onChange: N
            }
          ),
          /* @__PURE__ */ a.jsxs("div", { className: e.selectionBox, children: [
            /* @__PURE__ */ a.jsx(k.div, { className: e.knob, layout: !0, transition: v }),
            /* @__PURE__ */ a.jsx("span", { className: e.selectionBackground })
          ] })
        ]
      }
    );
  }
);
try {
  d.displayName = "ToggleSwitch", d.__docgenInfo = { description: "", displayName: "ToggleSwitch", props: { label: { defaultValue: null, description: "", name: "label", required: !0, type: { name: "ReactNode" } }, variant: { defaultValue: { value: "blue" }, description: "", name: "variant", required: !1, type: { name: "ToggleSwitchVariant" } }, labelHidden: { defaultValue: null, description: "", name: "labelHidden", required: !1, type: { name: "boolean" } }, labelPlacement: { defaultValue: { value: "right" }, description: "", name: "labelPlacement", required: !1, type: { name: "ToggleSwitchLabelPlacement" } }, size: { defaultValue: { value: "m" }, description: "", name: "size", required: !1, type: { name: "ToggleSwitchSize" } } } };
} catch {
}
export {
  d as ToggleSwitch
};
