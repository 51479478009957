import { Heading, Text, Icon, type HeadingTag, type IconMapMember } from '@carvertical/ui';
import cx from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import styles from './AccordionItem.module.scss';

type Variant = 'plain' | 'bordered';

type AccordionItemProps = {
  id: string;
  variant?: Variant;
  buttonNode: React.ReactNode;
  onClick: () => void;
  contentNode?: React.ReactNode;
  expanded?: boolean;
  headingLevel?: HeadingTag;
  buttonClassName?: string;
};

const ICON_BY_VARIANT: Record<Variant, Record<'expanded' | 'collapsed', IconMapMember>> = {
  plain: {
    expanded: 'minus-round-filled',
    collapsed: 'plus-round',
  },
  bordered: {
    expanded: 'minus-round-filled', // TODO: replace with minus icon when available
    collapsed: 'chevron-down',
  },
};

const ANIMATION_VARIANTS = {
  visible: {
    opacity: 1,
    height: 'auto',
    transition: {
      ease: 'easeInOut',
      duration: 0.3,
    },
  },
  hidden: {
    opacity: 0,
    height: 0,
    transition: {
      duration: 0.3,
      ease: 'easeOut',
    },
  },
};

const AccordionItem = ({
  buttonClassName,
  buttonNode,
  contentNode,
  expanded = false,
  headingLevel = 'h3',
  id,
  onClick,
  variant = 'plain',
}: AccordionItemProps) => {
  const panelId = `accordion-panel-${id}`;
  const headerId = `accordion-header-${id}`;

  const icon = ICON_BY_VARIANT[variant][expanded ? 'expanded' : 'collapsed'];
  const headingVariant = variant === 'plain' ? 's' : 'xs';

  return (
    <motion.div className={cx(styles.root, styles[variant])}>
      <Heading as={headingLevel} variant={headingVariant}>
        <button
          type="button"
          aria-expanded={expanded}
          aria-controls={panelId}
          id={headerId}
          className={cx(styles.button, buttonClassName)}
          onClick={onClick}
        >
          <Heading className={styles.text} as="span" variant={headingVariant}>
            {buttonNode}
          </Heading>
          <Icon name={icon} size="m" />
        </button>
      </Heading>

      <AnimatePresence initial={false}>
        {expanded && (
          <motion.section
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={ANIMATION_VARIANTS}
            id={panelId}
            aria-labelledby={headerId}
            className={styles.contentWrapper}
          >
            <Text variant="s" className={styles.content}>
              {contentNode}
            </Text>
          </motion.section>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export { AccordionItem };
export type { AccordionItemProps };
